<template>
  <div class="subpage-wrapper">
    <headerCom></headerCom>
    <bannerCom></bannerCom>

    <div class="content">
    
      <div class="body">
        <div style="overflow: hidden;" v-if="$route.query.f_title=='2'&&$route.query.s_title=='2-1'">

          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px;float:left">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>报表审计</span></p>
          </h2>

          <div class="sub_body" style="width: 900px;float: right;">


            <strong>概述:</strong>
            <p>财务报表审计，是指我们根据中国注册会计师审计准则的规定，对公司财务报表实施审计程序并出具审计报告，以提高财务报表预期使用者对财务报表的信赖程度。我们的财务报表审计服务主要包括以下项目：</p>
              
            <ul class="list-menu">
              <li>A.  各类企业的年度、半年度会计报表审计 </li>
              <li>B.  上股交、新三板企业的年度报告审计 </li>
              <li>C.  内外资企业合并、分立的审计和咨询 </li>
              <li>D.  内外资企业股权变更的审计和评估 </li>
            </ul>     
            
            
            <div class="contact" style="background: #fff;height: auto;margin-bottom: 100px;">
              <strong>客户案例:</strong>
              <ul class="contact-menu">
                <li>
                  <div class="img-wrapper">
                    <img src="../assets/images/see.png" />
                  </div>
                  <div class="content">
                    <h4>上海股权托管交易中心</h4>
                    <p>我们拥有OTC市场辅导和审计鉴证等丰富经验，成功为上海宝默家居股份有限公司、上海易晶电子股份有限公司等公司进行OTC挂牌，为安庆盛峰化工有限公司进行私募债的发行。</p>
                  </div>
                </li>
                <li>
                  <div class="img-wrapper">
                    <img src="../assets/images/abbott.png" />
                  </div>
                  <div class="content">
                    <h4>雅培贸易（上海）有限公司</h4>
                    <p>2014年度因奶粉供过于求导致大量过期奶粉报废，本所合伙人经调查取证出具存货损失鉴证报告，并被税务局采用，成功使此巨额损失在所得税税前予以扣除。</p>
                  </div>
                </li>
                <li>
                  <div class="img-wrapper">
                    <img src="../assets/images/baier.png" />
                  </div>
                  <div class="content">
                    <h4>拜尔材料科技（中国）有限公司</h4>
                    <p>该公司的外汇业务设计三家公司吸收合并、股权转让、境外借款等复杂经济事项，本所合伙人仔细研究外汇政策并出具了外汇审核报告，成功使拜尔公司顺利通过了2010-2014年度外汇年检。</p>
                  </div>
                </li>
                <li>
                  <div class="img-wrapper">
                    <img src="../assets/images/duomenlang.png" />
                  </div>
                  <div class="content">
                    <h4>多门朗工程技术咨询（上海）有限公司</h4>
                    <p>总公司设在英国的跨国公司，本所合伙人接受委托，为上海的子公司进行了按照国际财务报告准则（IFRS）下的2010年度至今的年度审计，积极与英国审计师交流沟通，获得很好的正面评价。</p>
                  </div>
                </li>
                <li>
                  <div class="img-wrapper">
                    <img src="../assets/images/evonik.png" />
                  </div>
                  <div class="content">
                    <h4>赢创特种化学（上海）有限公司（德资）</h4>
                    <p>全球领先的、最好的特种化学品公司之一。2018年本事务所为该公司的企业分立提供全流程的会计处理和税务处理的咨询服务，及审计和评估。企业分立是非常复杂的事项，本所CPA以优秀的专业胜任能力圆满帮助企业完成财税处理，并获得企业的翘指好评。</p>
                  </div>
                </li>
                <li>
                  <div class="img-wrapper">
                    <img src="../assets/images/aitan.png" />
                  </div>
                  <div class="content">
                    <h4>爱坦国际贸易（上海）有限公司（法资）</h4>
                    <p>注册于外高桥保税区，从事进出口和转口贸易。本所合伙人常年担任财税咨询顾问，特别是为其多名外籍人员代理个税申报和税务筹划，降低税负30%以上。</p>
                  </div>
                </li>
                <li>
                  <div class="img-wrapper">
                    <img src="../assets/images/diyuan.png" />
                  </div>
                  <div class="content">
                    <h4>萩原贸易（上海）有限公司（日资）</h4>
                    <p>从事电子产品的国际贸易。合伙人常年担任财税咨询顾问，并每月出具咨询报告供管理人员参考，有效降低了公司财务和税务上的潜在风险。</p>
                  </div>
                </li>
              </ul>
            </div>
                            
          </div>
        </div>


        <div style="overflow: hidden;" v-if="$route.query.f_title=='2'&&$route.query.s_title=='2-2'">

          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px;float:left">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>专项审计</span></p>
          </h2>

          <div class="sub_body" style="width: 900px;height: auto;float: right;">
            <strong>概述:</strong>
            <p> 专项审计是对某个具体项目的审计，其他的不涉及；而年度审计是对企业全面的审计，当然具体项目也包括在内。专项审计有以下项目类型：</p>
              <ul class="list-menu">
                <li> A.  高新技术企业认定审计</li>
                <li> B.  所得税汇算清缴审计</li>
                <li> C.  财产损失审计</li>
                <li> D.  税务注销审计</li>
                <li> E.  弥补亏损审计</li>
                <li> F.  股权转让审计</li>
                <li> G.  研发费用加计扣除审计</li>
                <li> H.  招投标审计</li>
                <li> I.  专项资金审计</li>
                <li> J.  离任审计</li>
                <li> K.  公司内部审计</li>
                <li> L.  清算审计</li>
              </ul>
              <div class="contact" style="background: #fff;height: auto;margin-bottom: 100px;">
                <strong>客户案例:</strong>
                <ul class="contact-menu">
                  <li>
                    <div class="content">
                      <p>本事务所每年为上海顶昂信息科技有限公司、上海吴淞电气实业有限公司、上海严复制药系统工程有限公司等50多家企业提供高新技术企业认定审计工作，累计已经数百家成功案例企业；以及每年为上百家公司提供所得税汇算清缴审计、股权转让、清算等专项审计，无失败案例。我们的审计工作得到基本所有客户的认可，没有一起项目半途而废或不欢而散，我们本着严谨的工作态度、专业和耐心做好每一项工作。</p>
                    </div>
                  </li>
                </ul>
              </div>
          </div>
        </div>
        <div style="overflow: hidden;" v-if="$route.query.f_title=='2'&&$route.query.s_title=='2-3'">

          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px;float:left">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>挂牌上市</span></p>
          </h2>

          <div class="sub_body" style="width: 900px;height: auto;float: right;">
            <strong>概述:</strong>
            <p>在上市过程的各个阶段，我们均能为企业提供全面的专业协助和辅导。我们的专业人员拥有非常丰富的经验。除审计上市财务报表外，我们还可就会计处理方法及应对监管机构咨询方面提供咨询意见。我们同时也提供IPO之前的相关服务， 包括就企业在营运、财务和管理团队等方面的准备提供咨询，协助联系保荐人和其他上市专业顾问，以及协助保荐人进行财务尽职调查等。</p>
            <ul class="list-menu">
              <li>A.  新三板挂牌</li>
              <li>B.  上股交E版挂牌</li>
              <li>C.  上股交Q版挂牌 </li>
              <li>D. 创业板挂牌</li>
            </ul>
            <div class="contact" style="background: #fff;height: auto;margin-bottom: 100px;">
                <strong>客户案例:</strong>
                <ul class="contact-menu">
                  <li>
                    <div class="content">
                      <p>本公司已经为上海宝默家居工程股份有限公司、上海易晶电子科技股份有限公司、上海利迪科技股份有限公司等十数家股份公司挂牌新三板和上海股交中心，并且作为常年审计机构，助力企业资本市场发展更上一层楼。</p>
                    </div>
                  </li>
                </ul>
              </div>
          </div>
        </div>
        <div style="overflow: hidden;" v-if="$route.query.f_title=='2'&&$route.query.s_title=='2-4'">
          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px;float:left">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>专业验资</span></p>
          </h2>

          <div class="sub_body" style="width: 900px;height: auto;float: right;">
            <strong>概述:</strong>
            <p>验资类型主要包括：</p>
            <ul class="list-menu block">
              <li>1.  设立验资 ：新公司注册时需向工商部门提交，以证明企业的注册资金。    </li>
              <li> 2.  设立的非首期验资 ：公司成立时的注册资本的非首期缴纳。     </li>
              <li> 3.  增资验资 ：企业在运营过程中，需增加注册资金时，需提供增资验资报告。    </li>
              <li>4.  减资验资报告 ：企业在运营过程中，需减少注册资金时，需提供减资验资报告。</li>
              <li>5.  内、外资企业利润转增资本验资、实物验资、无形资产验资、企业改制等其他类型的验资。 </li>
              <li>6.  定向增资、私募债等其他特殊目的的验资业务。</li>
            </ul>
            <div class="contact" style="background: #fff;height: auto;margin-bottom: 100px;">
              <strong>客户案例:</strong>
              <ul class="contact-menu">
                <li>
                  <div class="img-wrapper">
                    <img src="../assets/images/baier.png" />
                  </div>
                  <div class="content">
                    <h4>拜尔材料科技（中国）有限公司</h4>
                    <p> 该公司的外汇业务涉及3家公司吸收合并、股权转让、境外借款等复杂经济事项，本所合伙人仔细研究外汇政策并出具了外汇审核报告，成功使拜尔公司顺利通过了2016年度外汇年检 。</p>
                  </div>
                </li>
                <li>
                  <div class="img-wrapper">
                    <img src="../assets/images/pingan.png" />
                  </div>
                  <div class="content">
                    <h4>中国平安保险公司上海子公司、比英杰明（上海）贸易有限公司等</h4>
                    <p>本所合伙人10年经验为超过300家内资和外资企业做过货币及非货币各种类型的验资。</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div style="overflow: hidden;" v-if="$route.query.f_title=='3'&&$route.query.s_title=='3-1'">
          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px;float:left">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>公司注册</span></p>
          </h2>

          <div class="sub_body" style="width: 900px;height: auto;float: right;">
            <strong>概述:</strong>
            <p>注册公司所需材料:</p>
            <ul class="list-menu" style="margin-bottom: 30px;">
              <li> 1.  申请注册公司的法人，股东身份证复印件</li>
              <li>2.  申请注册公司的名称（最少取2个名称）</li>
              <li>3.  申请注册公司的经营范围（我公司有参考范本） </li>
              <li>4.  申请注册公司的法人，股东投资比例</li>
              <li> 5.  申请注册公司的法人，股东的联系电话</li>
            </ul>

            <p>公司经营范围参考:</p>
            <ul class="list-menu" style="margin-bottom: 30px;">
              <li> 1.  贸易销售类  </li>
              <li>2.  商务服务类 </li>
              <li>3.  工程设计类 </li>
              <li>4.  文化科技类 </li>
              <li> 5.  机械制造类</li>
            </ul>

            <p>公司注册详细流程:</p>
            <ul class="list-menu" style="margin-bottom: 30px;">
              <li> 1.  填写资料   </li>
              <li>2.  名称核准 </li>
              <li>3.   网上预申  </li>
              <li>4.  提交申请 </li>
              <li> 5.  领取执照</li>
            </ul>

            <p>公司注册详细流程:</p>
            <ul class="list-menu">
              <li> 1.  填写资料   </li>
              <li>2.  名称核准 </li>
              <li>3.   网上预申  </li>
              <li>4.  提交申请 </li>
              <li> 5.  领取执照</li>
              <li>6.  刻章、办组织机构代码证、税务登记证等</li>
              <li>7.  银行开户</li>
              <li>8.  税务报到</li>
            </ul>

          </div>
        </div>

        <div style="overflow: hidden;" v-if="$route.query.f_title=='3'&&$route.query.s_title=='3-2'">
          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px;float:left">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>公司变更</span></p>
          </h2>

          <div class="sub_body" style="width: 900px;height: auto;float: right;">
            <strong>概述:</strong>
            <p>公司变更注册登记事项范围:</p>
            <ul class="list-menu" style="margin-bottom: 30px;">
              <li>1.  企业名称变更 </li>
              <li>2.  企业法人变更</li>
              <li>3.  经营范围变更  </li>
              <li>4.  注册地址变更</li>
              <li>5.  股权变更 </li>
              <li>6.  注册资本变更</li>
            </ul>
          </div>

          <div class="sub_body" style="width: 900px;height: auto;float: right;">
            <p>公司变更注册登记事项所需要的材料:</p>
            <ul class="list-menu block">
              <li>1.  公司的营业执照、组织机构代码证、税务登记证、公章 </li>
              <li>2.  公司的章程及章程修正案（如遗失，可在工商局查询）</li>
              <li>3.  股东会决议  </li>
              <li>4.  注册资本变更需提供最近的验资报告（如有）</li>
              <li>5.  法人变更需提供新法人的身份证复印件及一寸照片2张 </li>
              <li>6.  注册地址变更需提供新地址的房产证复印件或街道证明及租赁协议</li>
            </ul>
          </div>

        </div>

        <div style="overflow: hidden;" v-if="$route.query.f_title=='3'&&$route.query.s_title=='3-3'">
          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px;float:left">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>公司注销</span></p>
          </h2>

          <div class="sub_body" style="width: 900px;height: auto;float: right;">
            <strong>概述:</strong>
            <p> 本事务所擅长为各种内、外资企业办理注销工作，特别是税务方面有各类复杂情况的企业，为企业避免税务风险节省税金！</p>
            <p>公司注销所需资料</p>
            <ul class="list-menu" style="margin-bottom: 30px;">
              <li>1.  营业执照正副本 </li>
              <li>2.  国地税注销证明</li>
              <li>3.  基本户注销证明 </li>
              <li>4. 资产负债表、损益表</li>
              <li>5.  公司章程 </li>
              <li>6. 法定代表人身份证</li>
              <li>7.  报纸公告   </li>
              <li>8. 公章、财务章、合同章</li>
              <li>9.  公司注销规定的其他资料</li>
            </ul>
          </div>

          <div class="sub_body" style="width: 900px;height: auto;float: right;">
            <p>公司注销流程</p>
            <ul class="list-menu" style="margin-bottom: 30px;">
              <li>1.  注销公司国、地税登记证 </li>
              <li>2.  到公司主管工商局办理《公司注销备案》</li>
              <li>3.  登报公告(登报45日后再去注销公司) </li>
              <li>4. 登报45日后，再次到工商局办理注销申请</li>
              <li>5.  注销社保公积金、银行账户</li>
            </ul>
          </div>

        </div>


        <div style="overflow: hidden;" v-if="$route.query.f_title=='4'&&$route.query.s_title=='4-1'">
          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px;float:left">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>财税顾问</span></p>
          </h2>

          <div class="sub_body" style="width: 900px;height: auto;float: right;">
            <strong>概述:</strong>
            <p> 税务顾问包括：</p>
            <ul class="list-menu block">
              <li>1、提供电话咨询服务，有偿受聘常年税务顾问。让客户及时了解财税政策文件以及税收优惠政策</li>
              <li>2、财税政策咨询，日常工作疑难问题解答</li>
              <li>3、定期审核企业账务、纳税申报资料，为企业财务核算、税务处理把关</li>
              <li>4、就客户生产、经营、管理等方面的重大决策提出税务咨询意见，或从税法上进行论证，提供税法依据</li>
              <li>5、纳税流程控制程序的制定及优化</li>
              <li> 6、对客户以往的涉税处理事项进行清理，并提出有针对性的补救措施与方案，化解其中潜在的纳税风险。</li>
            </ul>
          </div>
        </div>
        <div style="overflow: hidden;" v-if="$route.query.f_title=='4'&&$route.query.s_title=='4-2'">
          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px;float:left">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>税收筹划</span></p>
          </h2>

          <div class="sub_body" style="width: 900px;height: auto;float: right;">
            <strong>概述:</strong>
            <p>税收筹划包括：</p>
            <ul class="list-menu block">
              <li>1、根据企业类型和经营特点, 帮助企业争取最大限度地享受优惠政策。</li>
              <li>2、为客户制定最优的税务筹划方案，以最大限度的规避纳税风险，并合法实现客户的涉税利益，降低公司税负，避免税务风险。</li>
              <li>3、为企业在日常经营及对内对外投资、签订合同、招标、合并、分立、改组改制等方面，提供最合理、最优的节税方案；</li>
              <li>4、为新办企业确定最合理纳税方案，帮助企业减少税收成本,降低纳税风险，在合法的前提下，实现最小纳税,提高经济效益。</li>
              <li>5、企业整体税收筹划；分税种筹划（如个人所得税、企业所得税、土地增值税等）；分行业筹划（如房地产业、新技术企业等）</li>
              <li> 6、税务稽查前，受托对企业以往的纳税状况进行审查并出具纳税风险评估与化解报告，协助客户正确应对税务稽查。</li>
            </ul>
          </div>
        </div>
        <div style="overflow: hidden;" v-if="$route.query.f_title=='4'&&$route.query.s_title=='4-3'">
          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px;float:left">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>税务代理</span></p>
          </h2>

          <div class="sub_body" style="width: 900px;height: auto;float: right;">
            <strong>概述:</strong>
            <p>税务代理包括：</p>
            <ul class="list-menu block">
              <li>1、办理税务登记、变更税务登记和注销税务登记手续</li>
              <li>2、办理纳税申报或扣缴税款报告</li>
              <li>3、办理缴纳税款和申报退税手续</li>
              <li>4、代为申请减免税及其他税收优惠</li>
              <li>5、审查纳税情况；重要涉税文书的审核、制作代理税务行政复议，协调税务纠纷，代理制作涉税文书</li>
              <li>6、办理除增值税专用发票外的发票领购手续；参与客户重大涉税项目谈判，为客户进行投资、融资、并购、重组等重大经济行为，筹划合理的纳税方案，预防其中的纳税风险。</li>
              <li>7、涉税协调，在税收稽查、税收争议、税收处罚等方面，充分运用丰富的涉税经验和对税法的深刻、正确理解， 代企业与主管税务机关进行沟通协调，力求最合理地纳税。</li>
            </ul>
          </div>
        </div>
        <div style="overflow: hidden;" v-if="$route.query.f_title=='4'&&$route.query.s_title=='4-4'">
          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px;float:left">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>稽查应对</span></p>
          </h2>

          <div class="sub_body" style="width: 900px;height: auto;float: right;">
            <strong>概述:</strong>
            <p> 税务稽查应对包括：</p>
            <ul class="list-menu block">
              <li>1、在税务机关决定对企业正式进行税务稽查前，帮助企业迅速运用合理的策略和方法进行自查调整，变被动为主动，在最大限度内避免税务稽查对企业造成的经济损失。</li>
              <li>2、针对企业涉税问题提供全面的咨询意见，对客户稽查期间内的账务进行详细税务审计，并出具《企业税务风险评估报告》</li>
              <li>3、针对企业的账务情况，协助客户制定并实施税务调整方案；自查补税数额的确定，协助客户办理自查补税申报。</li>
              <li>4、协助客户准备税务稽查前的税务约谈，做好说解工作；协助客户针对税务稽查中提出的有关问题。提供税收政策支持依据，协助客户审查税务机关的稽查工作底稿。税务稽查结束后，针对本次税务稽查提出全面的专业性意见和后续化解方案。</li>
            </ul>
          </div>
        </div>

        <div style="overflow: hidden;" v-if="$route.query.f_title=='4'&&$route.query.s_title=='4-5'">
          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px;float:left">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>财税培训</span></p>
          </h2>

          <div class="sub_body" style="width: 900px;height: auto;float: right;">
            <strong>概述:</strong>
            <p>财税培训包括：</p>
            <ul class="list-menu block">
              <li>1、税收筹划培训；了解最新财税政策途径培训</li>
              <li>2、企业、集团内部分行业的财税知识培训</li>
              <li>3、会计人员岗位知识培训（分系列）</li>
              <li>4、出口退税类培训：出口退税实务操作专题培训；出口退税帐务处理专题培训；出口退税率的变化对企业的影响；</li>
              <li>5、广告行业税收政策及财务操作培训；</li>
              <li>6、新、旧企业会计制度比较；餐饮企业内部财务控制流程培训；加工贸易的帐务处理规范；生产企业成本构成、测试企业内部控制；外籍人员的个人所得税；外国企业常驻上海代表处相关税务问    题；出口退（免）税相关的税务、财务问题；外商投资企业税收优惠政策；企业内部控制制度及财务制度的建立；外商投资企业所得税的汇算清缴；新颁布的《小企业会计准则》；非贸易项目下的付汇开证。</li>
            </ul>
          </div>
        </div>
        <div style="overflow: hidden;" v-if="$route.query.f_title=='5'&&$route.query.s_title=='5-1'">
          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px;float:left">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>服务范围</span></p>
          </h2>

          <div class="sub_body" style="width: 900px;height: auto;float: right;">
            <strong>概述:</strong>
            <p>服务范围包括：</p>
            <ul class="list-menu block">
              <li>1、先整理乱账，理清账序，完善账套，规范做账。</li>
              <li>2、初始建帐、日常记帐；规范财务处理，对财务管理的每个环节把关，确保贵公司资产、资金不受损失。 </li>
              <li>3、编制各类会计报表，协助公司出纳人员与银行核对账务明细、成本核算等。</li>
              <li>4、认真履行会计职责,在提供会计服务的同时，我们会规范公司的财务行为及相关帐务处理，审核公司的各种原始凭证，完善公司的的财务管理制度。</li>
              <li>5、专业会计师做账，协调企业与主管税务机关的关系，帮助企业实现税收优惠，合理避税，降低税负。</li>
              <li>6、根据公司的考核制度，建立相应的会计核算制度，完善财务核算体系，比如按不同部门或不同业务员定期出具业绩报表或费用报表。</li>
            </ul>
          </div>
        </div>
        <div style="overflow: hidden;" v-if="$route.query.f_title=='5'&&$route.query.s_title=='5-2'">
          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px;float:left">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>独特优势</span></p>
          </h2>

          <div class="sub_body" style="width: 900px;height: auto;float: right;">
            <strong>概述:</strong>
            <p>独特优势包括：</p>
            <ul class="list-menu block">
              <li>1、适应各类企业的发展要求，兼职的低成本，全职的会计服务；</li>
              <li>2、专业的服务，合理避税，尽量让企业少交税；</li>
              <li>3、额外负担小。专业全面高效为您省钱；</li>
              <li>4、纳税申报及时，税务信息获取及时并立即与相关企业沟通；</li>
              <li>5、我们与您企业签订代理记账协议会比私人代理记账更安全可靠有保障；</li>
              <li>6、安全保密，严格恪守会计准则及职业道德，时刻接受客户的监督，保守客户企业的商业秘密。</li>
            </ul>
          </div>
        </div>

        <div style="overflow: hidden;" v-if="$route.query.f_title=='5'&&$route.query.s_title=='5-3'">
          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px;float:left">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>服务流程</span></p>
          </h2>

          <div class="sub_body" style="width: 900px;height: auto;float: right;">
            <strong>概述:</strong>
            <p>代理记账的流程：</p>
            <ul class="list-menu block">
              <li>1、我们会根据企业实际情况预先设计会计核算、完善财务制度及制定纳税方案。</li>
              <li>2、涉税协调，充分运用丰富的涉税经验和对税法的深刻、正确理解，代企业与主管税务机关进行沟通协调，力求最合理地纳税。</li>
              <li>3、建立完整的记账、核算、汇总报表并装订成册。</li>
              <li>4、最后电子报税及纳税</li>
            </ul>
          </div>
        </div>

        <div v-if="$route.query.f_title=='6'" style="overflow: hidden;">

          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px;float:left">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>企业简介</span></p>
          </h2>

          <div class="sub_body" style="width: 900px;float: right;">
            <strong style="margin-bottom: 30px;display: block;">独立、客观、公正是我们的执业准则；服务至上、信誉至上是我们的服务信条；
              崇尚职业理想，提倡勤勉尽责，鼓励团体精神，强调专业服务是我们的信念。</strong>
            <p style="text-indent: 30px;font-weight: normal;">上海茂恒会计师事务所 是经上海市财政局批准成立的社会审计服务机构。
            我们致力于提供专业化的服务和解决方案，为客户创造及增加价值。我们的员工
            具备专业的知识技能和丰富的业务经验，接受职业道德教育，充满激情，敬业勤奋，
            我们的工作更富有成效。我们具有完善的业务流程和部门体系，建立了一整套完善的
            管理制度体系，制定了严格的工作标准以及质量控制程序，以确保我们的工作能更符
            合法律规范并满足委托人的要求。</p><br />

            <p style="text-indent: 30px;font-weight: normal;">良好的职业操守，严谨的执业习惯，勤勉的工作作风，得到了客户的认可，使我
            们拥有了一大批较为稳定的客户群体。我们拥有国际化的视野，能够提供具有国际水
            准的专业服务。我们的专业团队32人，其中注册会计师9人；立足于上海12年发展，
            专业、稳健、服务好客户才能走的长久。我们分别于2012年和2017年在上海注册会计
            师协会的分类管理考评中取得B类资格，在2016年和2018年分别荣获上海股权交易托
            管中心授予的“完成挂牌项目最多会计师事务所”和“最佳专业服务机构”称号。因为专业，
            值得信赖！我们期待着与更多的政府部门和企业建立合作关系并提供优质的服务。</p>

          </div>

          <img src="../assets/images/zongzhi.png" style="height: 500px;width: 100%;object-fit: cover;margin: 50px 0 100px 0" />
        
          

          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px;float:left">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>我们的承诺</span></p>
          </h2>

          <div class="sub_body" style="width: 900px;float: right;">
            <ul style="overflow: hidden;">
              <li style="width: 380px;margin:0 40px 40px 0;height:250px;float:left;">
                <p style="font-size:18px;font-weight: 700;margin-bottom: 10px;"><i style="width: 20px;height:0px;border:2px solid #333;display: inline-block;margin: 0 10px 4px 0;"></i>专业的审计服务</p>
                我们根据客户不同行业的特点和具体情况，坚持风险导向的审计理念，针对不同风险事项实施必要的审计程序。我们以独立客观公正的执业态度，使审计报告能够提供更多有价值的财务信息，保证审计质量，让使用者能够信赖我们的审计报告。
              </li>
              <li style="width: 380px;height:250px;float:left;">
                <p style="font-size:18px;font-weight: 700;margin-bottom: 10px;"><i style="width: 20px;height:0px;border:2px solid #333;display: inline-block;margin: 0 10px 4px 0;"></i>贴心的财务顾问</p>
                我们根据客户的自身需求，站在客户的角度，利用客户的产品和服务及其他社会资源，为客户的日常经营管理、财务管理和对外资本运作等经济活动进行财务策划和方案设计等。根据双方约定的财务顾问服务范围和服务方式，担任企业的财务顾问并为客户直接提供日常咨询服务和专项顾问服务。
              </li>
              <li style="width: 380px;margin:0 40px 40px 0;height:250px;float:left;">
                <p style="font-size:18px;font-weight: 700;margin-bottom: 10px;"><i style="width: 20px;height:0px;border:2px solid #333;display: inline-block;margin: 0 10px 4px 0;"></i>专业的税务服务</p>
                我们对纳税人、扣缴义务人以及其他单位和个人的税务专业请求提供帮助，主要有税收政策、办税程序及有关涉税事项等。在当前这种机构不断优化，核心竞争力不断提升的趋势下，传统的、被动的税务管理模式已经不能满足公司发展的需要。我们利用专业的知识，创新的税务筹划使企业合规化，并为企业系统地制定卓有成效的税务优化规划，帮助企业有效降低税务风险、达到税务负担最优化。
              </li>

            </ul>
            

          </div>

          
          <img src="../assets/images/sub-banner.jpg" style="height: 300px;width: 100%;object-fit: cover;margin: 50px 0   0px 0" />
        <strong style="margin-bottom: 100px;display: block;text-align: right;">用我们的专业服务，给您最优的解决方案！</strong>
        
        </div>




      </div>



    </div>
    <el-footer>
      <footerCom></footerCom>
    </el-footer>
    
  </div>
</template>

<script>
import headerCom from './children/header.vue' 
import bannerCom from './children/banner.vue'
import footerCom from './children/footer.vue'  
  export default {
    components: { headerCom,bannerCom,footerCom },
    data() {
      return {
       
      }
    },
    created(){

    },
    mounted() {
      // 实现swiper双向控制
      this.$nextTick(() => {

        
      })
    },
    methods: {
     
    }
  }
</script>

<style lang="scss">
.subpage-wrapper{


  
  .content {
    min-height: 300px;
    width: 1200px;
    margin: auto;
    padding-top: 100px;
    color: #333;

    h2 {
      text-align: center;
      color:#fff;
      font-size: 38px;
      font-weight: normal;
      display: inline-block;
      transform: translate(-50%, 0);
    }
    ul.list-menu {
      width: 860px;
      overflow: hidden;
      margin: 20px auto 100px;

      li{
        float: left;
        width: 50%;
        margin: 10px 0;
      }
      &.block {
        li {
          width: 100%;

        }
      }
    }
    strong {
      font-size: 20px;
      letter-spacing: 2px;
      line-height: 32px;
      margin: 10px 0;
      display: block;
    }
    .contact {
      ul {
        padding: 0;
        margin: 0;
        li {
          // background: #E0EBED;
          width: 100%;
          height: 120px;
          margin: 20px 0;
          overflow: hidden;
          .img-wrapper {
            overflow: hidden;
            height: 120px;
            // margin-top: 35px;
            width: 130px;
            float: left;
            img {
              display: block;
              // width: auto;
              // height: 100%;
            }
          }
          .content {
            width: 765px;
            float: left;
            padding: 0px;
            box-sizing: border-box;
            position: relative;height: 100%;
            h4 {
              font-size: 18px;
              font-weight: 700;
              // line-height: 1.4;
              margin: 0 0 6px 0;
              // margin-bottom: 22px;
              color: #585858;
            }
            p {
              font-size: 16px;
              line-height: 1.6;
              margin-bottom: 22px;
            }
            a {font-size: 15px;color:#177790;cursor: pointer;}
          }
        }
      }
    }
    .sub_body {
      font-size: 16px;
      line-height: 32px;
      p {
        // font-weight: 700;
      }
    }
  }

  .el-footer {
    width:100%;
    padding: 0;
    height: 300px !important;
    background: #181818;
  }
}

@media screen and (max-width:991px) {
  .subpage-wrapper {
    .banner{
      height: 350px;
      .banner_titile {
        width: 100%;
        top: 60px;
        left: 0;
        padding: 0 30px;
        box-sizing: border-box;
        h3 {
          font-size: 26px;
        }
        p {
          font-size: 18px;
        }
      }
      .banner_menu {
        top: 200px;
        left: 0;
        width: 100%;
      }
    } 
    .content {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 10px;
      padding-top: 0;
      h2 {
        display: none;
      }
      .sub_body {
        width: 100%!important;
        float: none!important;
        padding: 0 15px!important;
        box-sizing: border-box;
        .list-menu {
          li {
            float: none;
          }
        }
      }
      .contact {
        .contact-menu {
          li {
            height: auto!important;
          }
          
          .content {
            min-height: 10px !important;
            margin-top: 0!important;
            width: calc(100% - 130px)!important;
            height: auto!important;
          }
        }
      }
    }
  }
}

</style>


