<template>
  <div class="bannerCom">
    <div class="block banner">
      <div class="banner_titile">

        <div v-if="$route.query.f_title=='2'">
          <h3>审计验资 </h3>
          <p> 在审计验资方面为客户提供专业服务：年报（合并）、高新申报、财务尽调、绩效、招投标、股权转让等各类审计。</p>
        </div>

        <div v-if="$route.query.f_title=='3'">
          <h3>公司代理 </h3>
          <p> 本着专业高效规范严谨、诚信可靠客户至上的理念，为客户贴心完美的服务，努力降低企业财务成本、时间成本。</p>
        </div>

        <div v-if="$route.query.f_title=='4'">
          <h3>财税咨询 </h3>
          <p>实施相应的服务策略，成为客户依法纳税的助手，合理避税的参谋。让客户享受到真正的管家式贴心服务。</p>
        </div>

        <div v-if="$route.query.f_title=='5'">
          <h3>代理记账 </h3>
          <p> 严格遵守会计准则和职业道德，确保公司财产不受损失。回馈给客户高质量、高效率的服务。</p>
        </div>

        <div v-if="$route.query.f_title=='6'">
          <h3>关于我们 </h3>
          <p> 独立、客观、公正是我们的执业准则；服务至上、信誉至上是我们的服务信条。</p>
        </div>

      </div>

      <div class="banner_menu">
        <ul>
          <li v-if="$route.query.f_title=='2'" @click="goTopage('2','2-1')" :class="[$route.query.s_title=='2-1'?'active':'']">报表审计</li>
          <li v-if="$route.query.f_title=='2'" @click="goTopage('2','2-2')" :class="[$route.query.s_title=='2-2'?'active':'']">专项审计</li>
          <li v-if="$route.query.f_title=='2'" @click="goTopage('2','2-3')" :class="[$route.query.s_title=='2-3'?'active':'']">挂牌上市</li>
          <li v-if="$route.query.f_title=='2'" @click="goTopage('2','2-4')" :class="[$route.query.s_title=='2-4'?'active':'']">专业验资</li>
          
          <li v-if="$route.query.f_title=='3'" @click="goTopage('3','3-1')" :class="[$route.query.s_title=='3-1'?'active':'']">公司注册</li>
          <li v-if="$route.query.f_title=='3'" @click="goTopage('3','3-2')" :class="[$route.query.s_title=='3-2'?'active':'']">公司变更</li>
          <li v-if="$route.query.f_title=='3'" @click="goTopage('3','3-3')" :class="[$route.query.s_title=='3-3'?'active':'']">公司注销</li>
          
          <li v-if="$route.query.f_title=='4'" @click="goTopage('4','4-1')" :class="[$route.query.s_title=='4-1'?'active':'']">税务顾问</li>
          <li v-if="$route.query.f_title=='4'" @click="goTopage('4','4-2')" :class="[$route.query.s_title=='4-2'?'active':'']">税收策划</li>
          <li v-if="$route.query.f_title=='4'" @click="goTopage('4','4-3')" :class="[$route.query.s_title=='4-3'?'active':'']">税务代理</li>
          <li v-if="$route.query.f_title=='4'" @click="goTopage('4','4-4')" :class="[$route.query.s_title=='4-4'?'active':'']">稽查应对</li>
          <li v-if="$route.query.f_title=='4'" @click="goTopage('4','4-5')" :class="[$route.query.s_title=='4-5'?'active':'']">财税培训</li>
          
          <li v-if="$route.query.f_title=='5'" @click="goTopage('5','5-1')" :class="[$route.query.s_title=='5-1'?'active':'']">服务范围</li>
          <li v-if="$route.query.f_title=='5'" @click="goTopage('5','5-2')" :class="[$route.query.s_title=='5-2'?'active':'']">独特优势</li>
          <li v-if="$route.query.f_title=='5'" @click="goTopage('5','5-3')" :class="[$route.query.s_title=='5-3'?'active':'']">服务流程</li>
          
        </ul>
      </div>

    </div>
  </div>
</template>
  
  <script>
    export default {
      data() {
        return {
            isMobile: true,
            sub_title: '',
            subArr:{'2-1':'报表审计','2-2':'专项审计','2-3':'挂牌上市','2-4':'专业验资','3-1':'公司注册','3-2':'公司变更','3-3':'公司注销','4-1':'税务顾问',
            '4-2':'税收策划','4-3':'税务代理','4-4':'稽查应对','4-5':'财税培训','5-1':'服务范围','5-2':'独特优势','5-3':'服务流程','6':'关于我们'}
        }
      },
      created(){
  
      },
      mounted() {
        // 实现swiper双向控制
        this.$nextTick(() => {
          this.subPage = this.$route.query.s_title;
          this.sub_title = this.subArr[this.subPage];
        })
      },
      methods: {
        routerFn(newVal,oldVal){
          if(newVal.query.s_title) this.sub_title = this.subArr[newVal.query.s_title];
          else this.sub_title = this.subArr[newVal.query.f_title];
        },
        goTopage(f,s){
          this.$router.push({'path':'/subpage',query:{f_title:f,s_title:s}})
        }
      },
      watch:{
        '$route': 'routerFn'
      }
    }
  </script>


  <style lang="scss">
  // .banner {
  //   background-image: url("../../assets/images/sub-banner.jpg");
  //   background-size: 100% auto;
  //   background-attachment:fixed;
  //   background-repeat:no-repeat;
  //   background-size: cover;
  //   background-position: center -130px;
  // }
.bannerCom{
  .banner {
    background-image: url("../../assets/images/sub-banner.jpg");
    background-size: 100% auto;
    background-attachment:fixed;
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center -130px;
    height:500px;margin: 0;overflow: hidden;position: relative;
    .banner_titile {
      position: absolute;top: 150px;left: calc(50% - 600px);width: 1200px;z-index: 100;
      h3 {
        color: #fff;font-weight: 700;margin-bottom: 25px;font-size: 60px;letter-spacing: 5px;overflow: hidden;
      }
      p {
        color: #fff;
        font-size: 22px;
        letter-spacing: 2px;
        margin-bottom: 90px;
      }
    }
    .banner_menu {
      position: absolute;top: 350px;left: calc(50% - 600px);width: 1200px;z-index: 100;
      ul {
        overflow: hidden;
        li {
          float: left;
          margin: 0 10px;
          color: #fff;
          font-size: 18px;
          padding: 10px 20px;
          cursor: pointer;
          border-radius: 30px;
          letter-spacing: 1px;
          text-align: center;
          width: 128px;
          &:hover {
            transition: all .2s ease;
            background-color: #177790;
            color: #fff;
            list-style: disc inside;
          }
          &.active {
            background-color: #177790;
            color: #fff;
            list-style: disc inside;
          }
        }
      }
    }
  }
}

@media screen and (max-width:991px) {
  .bannerCom{
    .banner_menu {
      top: 230px!important;
      ul {
        margin: 30px 20px;
        li {
          padding: 6px 8px !important;
          width: 90px !important;
          margin: 0 2px !important;
          &:hover {
            list-style: none!important;
          }
          &.active {
            list-style: none!important;
          }
        }
      } 
    }
    .content {
      padding-top: 0!important;
      h2 {
        display: none!important;
      }
      .sub_body {
        width: 100%!important;
        float: none!important;
        padding: 0 15px !important;
        box-sizing: border-box;
        ul.list-menu {
          width: 100% !important;
          margin-bottom: 30px!important;
        }
        .contact {
          ul.contact-menu {
            li {
              
              height: auto !important;
              .content {
                margin-top: 0!important;
                min-height: 10px !important;
                height: auto!important;
                width: calc(100% - 130px)!important;
              }
            }
          }
        }
      }
    }
  }
}
</style>